import logo from './logo.svg';
import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./components/pages/Home/HomePage";
import ContactPage from "./components/pages/Contact/ContactPage";
import ServicesPage from "./components/pages/Services/ServicesPage";
import AboutPage from './components/pages/About/AboutPage';
import Navbar from './components/nav/Navbar';
import Footer from "./components/footer/Footer";
import TopBar from "./components/top-bar/TopBar";

function App() {
  return (
    <div className="App">
      <Router>
        {/*<TopBar/>*/}
        <Navbar/>

        <Routes>
          <Route path='/' exact element={<Home/>}/>
          {/*<Route path='/despre' exact element={<AboutPage/>}/>*/}
          <Route path='/servicii' exact element={<ServicesPage/>}/>
          <Route path='/contact' exact element={<ContactPage/>}/>
        </Routes>

        <Footer/>
      </Router>
    </div>
  );
}

export default App;
